.sources {
	position: relative;
	backdrop-filter: blur(20px);
	z-index: 200;
}

@media (min-width: 991px) {
	.sources {
		transform: translate(-20px, 40px);
	}
}

@media (max-width: 991px) {
	.title-container {
		flex-direction: column;
		align-items: flex-start;
	}

	.title-container .sources {
		margin-top: 30px;
	}
}

.sources::after {
	content: '▼';
	font-size: 0.6em;
	color: rgba(255, 255, 255, 0.5);
	display: block;
	position: absolute;
	top: 50%;
	right: 5px;
	transform: translate(-10px, -50%);
}

.sources select {
	padding: 0.6em 1.2em;
	padding-bottom: 0.8em;
	background: rgba(0, 0, 0, 0.1);
	border: 2px solid rgba(255, 255, 255, 0.1);
	color: hsl(200, 5%, 76%);
	min-width: 150px;
	outline: none;
	border-radius: 4px;
	-webkit-appearance: none;
	cursor: pointer;
	padding-right: 3em;
	letter-spacing: 0.05em;
}

.sources select:focus {
	box-shadow: 0 0 4px rgba(255, 255, 255, 0.2);
}
