.blurry {
	position: absolute;
	z-index: -1;
	border-radius: 50%;
	filter: blur(40px);
}

.blurry-1 {
	width: 200px;
	height: 300px;
	background: #220e5a;
	top: 300px;
	left: 20%;
	transform: scale(3) rotate(40deg);
	opacity: 0.5;
}

.blurry-2 {
	width: 200px;
	height: 300px;
	background: #43192e;
	top: 300px;
	right: 30%;
	transform: scale(3) rotate(40deg);
	opacity: 0.5;
}

@media (max-width: 800px) {
	.blurry-1 {
		transform: scale(2) rotate(40deg);
		top: 200px;
	}

	.blurry-2 {
		transform: scale(2) rotate(40deg);
		top: 600px;
	}
}
