.CodeMirror {
  height: 300px;
  color: #000;
  direction: ltr;
  font-family: IBM Plex Mono, monospace;
}

.CodeMirror-lines {
  padding: 4px 0;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px;
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: #fff;
}

.CodeMirror-gutters {
  white-space: nowrap;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
}

.CodeMirror-linenumber {
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
  padding: 0 3px 0 5px;
}

.CodeMirror-guttermarker {
  color: #000;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

.CodeMirror-cursor {
  width: 0;
  border-left: 1px solid #000;
  border-right: none;
}

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  background: #7e7;
  border: 0 !important;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor-mark {
  background-color: #14ff1480;
  animation: blink 1.06s step-end infinite;
}

.cm-animate-fat-cursor {
  width: auto;
  background-color: #7e7;
  border: 0;
  animation: blink 1.06s step-end infinite;
}

@keyframes blink {
  0% {
  }

  50% {
    background-color: #0000;
  }

  100% {
  }
}

.cm-tab {
  text-decoration: inherit;
  display: inline-block;
}

.CodeMirror-rulers {
  position: absolute;
  inset: -50px 0 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute;
  top: 0;
  bottom: 0;
}

.cm-s-default .cm-header {
  color: #00f;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-variable-3, .cm-s-default .cm-type {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta, .cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error, .cm-invalidchar {
  color: red;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}

.CodeMirror-matchingtag {
  background: #ff96004d;
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

.CodeMirror {
  background: #fff;
  position: relative;
  overflow: hidden;
}

.CodeMirror-scroll {
  height: 100%;
  outline: none;
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  position: relative;
  overflow: scroll !important;
}

.CodeMirror-sizer {
  border-right: 30px solid #0000;
  position: relative;
}

.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  z-index: 6;
  display: none;
  position: absolute;
}

.CodeMirror-vscrollbar {
  top: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.CodeMirror-scrollbar-filler {
  bottom: 0;
  right: 0;
}

.CodeMirror-gutter-filler {
  bottom: 0;
  left: 0;
}

.CodeMirror-gutters {
  min-height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  vertical-align: top;
  margin-bottom: -30px;
  display: inline-block;
}

.CodeMirror-gutter-wrapper {
  z-index: 4;
  position: absolute;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  z-index: 4;
  position: absolute;
  top: 0;
  bottom: 0;
}

.CodeMirror-gutter-elt {
  cursor: default;
  z-index: 4;
  position: absolute;
}

.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: #0000;
}

.CodeMirror-gutter-wrapper ::selection {
  background-color: #0000;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  font-family: inherit;
  font-size: inherit;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
  background: none;
  border-width: 0;
  border-radius: 0;
  margin: 0;
  position: relative;
  overflow: visible;
}

.CodeMirror-wrap pre.CodeMirror-line, .CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  z-index: 0;
  position: absolute;
  inset: 0;
}

.CodeMirror-linewidget {
  z-index: 2;
  padding: .1px;
  position: relative;
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

.CodeMirror-scroll, .CodeMirror-sizer, .CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-measure {
  width: 100%;
  height: 0;
  visibility: hidden;
  position: absolute;
  overflow: hidden;
}

.CodeMirror-cursor {
  pointer-events: none;
  position: absolute;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  z-index: 3;
  position: relative;
}

div.CodeMirror-dragcursors, .CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ff06;
}

.cm-force-border {
  padding-right: .1px;
}

@media print {
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

.cm-tab-wrap-hack:after {
  content: "";
}

span.CodeMirror-selectedtext {
  background: none;
}

.cm-s-material.CodeMirror {
  color: #eff;
  background-color: #263238;
}

.cm-s-material .CodeMirror-gutters {
  color: #546e7a;
  background: #263238;
  border: none;
}

.cm-s-material .CodeMirror-guttermarker, .cm-s-material .CodeMirror-guttermarker-subtle, .cm-s-material .CodeMirror-linenumber {
  color: #546e7a;
}

.cm-s-material .CodeMirror-cursor {
  border-left: 1px solid #fc0;
}

.cm-s-material div.CodeMirror-selected {
  background: #80cbc433;
}

.cm-s-material.CodeMirror-focused div.CodeMirror-selected {
  background: #ffffff1a;
}

.cm-s-material .CodeMirror-line::-moz-selection, .cm-s-material .CodeMirror-line > span::-moz-selection, .cm-s-material .CodeMirror-line > span > span::-moz-selection {
  background: #80cbc433;
}

.cm-s-material .CodeMirror-line::selection, .cm-s-material .CodeMirror-line > span::selection, .cm-s-material .CodeMirror-line > span > span::selection {
  background: #80cbc433;
}

.cm-s-material .CodeMirror-activeline-background {
  background: none !important;
}

.cm-s-material.CodeMirror-focused .CodeMirror-activeline-background {
  border: 1px solid #ffffff1a;
  border-radius: 3px;
  background: #ffffff14 !important;
}

.cm-s-material .cm-keyword {
  color: #c792ea;
}

.cm-s-material .cm-operator {
  color: #89ddff;
}

.cm-s-material .cm-variable-2 {
  color: #eff;
}

.cm-s-material .cm-variable-3, .cm-s-material .cm-type {
  color: #f07178;
}

.cm-s-material .cm-builtin {
  color: #ffcb6b;
}

.cm-s-material .cm-atom {
  color: #f78c6c;
}

.cm-s-material .cm-number {
  color: #ff5370;
}

.cm-s-material .cm-def {
  color: #82aaff;
}

.cm-s-material .cm-string {
  color: #c3e88d;
}

.cm-s-material .cm-string-2 {
  color: #f07178;
}

.cm-s-material .cm-comment {
  color: #ffffff4d;
}

.cm-s-material .cm-variable {
  color: #f07178;
}

.cm-s-material .cm-tag {
  color: #ff5370;
}

.cm-s-material .cm-meta {
  color: #ffcb6b;
}

.cm-s-material .cm-attribute, .cm-s-material .cm-property {
  color: #c792ea;
}

.cm-s-material .cm-qualifier, .cm-s-material .cm-variable-3, .cm-s-material .cm-type {
  color: #decb6b;
}

.cm-s-material .cm-error {
  color: #fff;
  background-color: #ff5370;
}

.cm-s-material .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: #fff !important;
}

.CodeMirror-matchingtag {
  background: #ff545424;
}

@font-face {
  font-family: Alliance No\.1;
  font-style: normal;
  font-weight: 400;
  src: url("Alliance-No-1-Regular.962a0e5e.woff2") format("woff2");
  font-display: swap;
}

body {
  font-feature-settings: "ss02" on, "ss01" on;
  color: #fff;
  min-height: 100vh;
  margin: 0;
  font-family: Alliance No\.1, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 16px;
}

html, body {
  position: relative;
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.section-container {
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

section {
  overflow: hidden;
}

main {
  max-width: 1400px;
  margin: 0 auto;
  padding: 40px;
}

body {
  background-color: #423f42;
  background-image: linear-gradient(90deg, #000 21px, #0000 1%), linear-gradient(#000 21px, #0000 1%), none;
  background-position: top;
  background-repeat: repeat, repeat, repeat;
  background-size: 22px 22px;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box, padding-box;
  background-clip: border-box, border-box, border-box;
}

h1 {
  margin-bottom: 40px;
  font-size: 40px;
  font-weight: 500;
}

h2 {
  margin: 0;
  font-size: 22px;
  font-weight: 400;
}

section {
  margin-bottom: 30px;
}

.CodeMirror {
  font-size: 16px;
}

@media (max-width: 991px) {
  .section-container {
    height: auto;
    display: block;
  }

  main {
    padding: 20px;
  }

  .CodeMirror {
    font-size: 12px;
  }
}

.title-container {
  min-height: 50px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  display: flex;
}

.CodeMirror {
  border: 2px solid #ffffff1a;
  padding: 10px;
  position: relative;
}

.custom-editor .CodeMirror {
  height: inherit;
  background-color: #0000004d;
  border-radius: 4px;
}

.custom-editor .CodeMirror-simplescroll-vertical {
  height: 0;
}

.CodeMirror-line {
  line-height: 1.6 !important;
}

.editor-section {
  flex-direction: column;
  display: flex;
}

.custom-editor {
  flex-grow: 1;
  position: relative;
}

.custom-editor .CodeMirror {
  height: 100%;
}

.compiled .cm-s-material .CodeMirror-activeline-background {
  background: none;
}

.source-title {
  color: #72a5fd;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.compile-title {
  color: #d85a99;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.CodeMirror-hscrollbar {
  display: none !important;
}

.sources select {
  font-family: inherit;
}

.sources select option {
  background: #000;
  margin-bottom: 10px;
  padding: 1em;
}

.github-link {
  position: absolute;
  top: 30px;
  right: 30px;
}

h1 {
  padding-right: 50px;
}

@media (min-width: 991px) {
  section.editor-section {
    min-height: 300px;
  }
}

.sources {
  backdrop-filter: blur(20px);
  z-index: 200;
  position: relative;
}

@media (min-width: 991px) {
  .sources {
    transform: translate(-20px, 40px);
  }
}

@media (max-width: 991px) {
  .title-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .title-container .sources {
    margin-top: 30px;
  }
}

.sources:after {
  content: "▼";
  color: #ffffff80;
  font-size: .6em;
  display: block;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(-10px, -50%);
}

.sources select {
  color: #bfc3c5;
  min-width: 150px;
  -webkit-appearance: none;
  cursor: pointer;
  letter-spacing: .05em;
  background: #0000001a;
  border: 2px solid #ffffff1a;
  border-radius: 4px;
  outline: none;
  padding: .6em 3em .8em 1.2em;
}

.sources select:focus {
  box-shadow: 0 0 4px #fff3;
}

.blurry {
  z-index: -1;
  filter: blur(40px);
  border-radius: 50%;
  position: absolute;
}

.blurry-1 {
  width: 200px;
  height: 300px;
  opacity: .5;
  background: #220e5a;
  top: 300px;
  left: 20%;
  transform: scale(3)rotate(40deg);
}

.blurry-2 {
  width: 200px;
  height: 300px;
  opacity: .5;
  background: #43192e;
  top: 300px;
  right: 30%;
  transform: scale(3)rotate(40deg);
}

@media (max-width: 800px) {
  .blurry-1 {
    top: 200px;
    transform: scale(2)rotate(40deg);
  }

  .blurry-2 {
    top: 600px;
    transform: scale(2)rotate(40deg);
  }
}

/*# sourceMappingURL=index.f49235c9.css.map */
